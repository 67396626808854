<script>
import { mapState, mapActions } from "vuex";
import TransferType from "@/components/transfers/TransferType.vue";
import TransferHistoryList from "@/components/transfers/TransferHistoryList.vue";
import MainButton from "@/components/helpers/MainButton";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  name: "Transfers",

  components: {
    MainButton,
    TransferType,
    TransferHistoryList,
    MainLoader,
  },

  data() {
    return {
      loading: true,
      params: {
        page: 1,
      },

      transferTypes: [
        {
          href: "TransferToAccount",
          icon: require("../../assets/icons/transfer/account.svg"),
          title: "Между своими счетами",
        },
        {
          href: "TransferToClient",
          icon: require("../../assets/icons/transfer/client.svg"),
          title: "Другому пользователю",
        },
      ],
    };
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.loadTransfers(this.params);
      },
    },
  },

  computed: {
    ...mapState({
      transfers: (state) => state.transfer.transfers,
      transfersLastPage: (state) => state.transfer.transfersLastPage,
      transfersStatus: (state) => state.transfer.transfersStatus,
    }),
  },

  methods: {
    ...mapActions({
      loadTransfers: "transfer/loadTransfers",
    }),
  },

  mounted() {
    this.loadTransfers(this.params).finally(() => {
      this.loading = false;
    });
  },
};
</script>

<template>
  <div class="transfers">
    <div class="transfers__types default-content-padding">
      <TransferType
        v-for="transferType in transferTypes"
        :key="transferType.href"
        :transfer-type="transferType"
      />
    </div>

    <div class="transfers__history default-content-padding">
      <MainLoader v-if="loading" />

      <template v-else>
        <TransferHistoryList :transfers="transfers" />

        <MainButton
          v-if="transfersLastPage > params.page"
          class="transfers__btn"
          color="dark"
          title="Показать еще"
          :disabled="transfersStatus === 'LOADING'"
          @click.native="params.page++"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.transfers {
  display: flex;
  flex-flow: column;

  &__types {
    display: flex;
    overflow: auto;
  }

  &__history {
    width: 100%;
    background-color: $background-white;
    padding-top: 24px;
    padding-bottom: 24px;
    margin: $space-xxxl 0 0;
  }

  &__btn {
    max-width: 180px;
  }
}
</style>
