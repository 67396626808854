<script>
import TransferHistoryItem from "@/components/transfers/TransferHistoryItem.vue";

export default {
  components: {
    TransferHistoryItem,
  },

  props: {
    transfers: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="transfer-history-list">
    <h2 class="transfer-history-list__title">История переводов</h2>

    <div class="transfer-history-list__wrapper">
      <transfer-history-item
        v-for="transfer in transfers"
        :key="transfer.id"
        :transfer="transfer"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transfer-history-list {
  &__title {
    @include title-3;
    color: $dark-primary;
  }

  &__wrapper {
    max-width: 700px;
    margin: $space-xl 0;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
}
</style>
