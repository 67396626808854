export default function defineTransferAccountType(account, transferType) {
  switch (account.user_type) {
    case "distributor":
      switch (account.account_type) {
        case "rc":
          return transferType === "from"
            ? "дистрибьюторского"
            : "дистрибьюторский";

        case "cooperative":
          return transferType === "from" ? "кооперативного" : "кооперативный";
      }

    case "client":
      switch (account.account_type) {
        case "rc":
          return transferType === "from" ? "клиентского" : "клиентский";
      }
  }
}
