<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    transferType: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    showTransferType() {
      return (
        (this.user?.tariff && this.user?.tariff?.code !== "free_c") ||
        this.transferType.href !== "TransferToClient"
      );
    },

    isDisabledTransferType() {
      return this.transferType?.disabled ?? false;
    },
  },

  methods: {
    ...mapActions({
      createNotification: "createNotification",
    }),

    showNotice() {
      if (this.isDisabledTransferType) {
        this.createNotification({
          id: "transferToClient",
          title: "Переводы другим пользователям больше не доступны",
          description: `Поделиться баллами RC для покупки тарифа можно с помощью выпуска сертификатов`,
          button_link: `${process.env.VUE_APP_FRONT_PANEL_URL}certificates`,
          button_label: "Перейти к сертификатам",
          closed: true,
          type: "info",
        });
      }
    },
  },
};
</script>

<template>
  <component
    :is="!isDisabledTransferType ? 'router-link' : 'div'"
    v-if="showTransferType"
    :to="{ name: transferType.href }"
    class="transfer-type"
    :class="{ 'transfer-type_disabled': isDisabledTransferType }"
    @click="showNotice"
  >
    <div class="transfer-type__icon">
      <img :src="transferType.icon" alt="" />
    </div>

    <div class="transfer-type__title">
      {{ transferType.title }}
    </div>
  </component>
</template>

<style scoped lang="scss">
.transfer-type {
  width: 160px;
  background-color: $background-white;
  padding: $space-inset-m;
  text-decoration: none;
  margin: 0 $space-m 0 0;
  border-radius: 8px;

  @media (max-width: 375px) {
    width: 135px;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    background-color: #f3f3f3;
  }

  &:active {
    background-color: #f8f8f8;
  }

  &_disabled {
    background-color: $light-third;
    cursor: pointer;
  }

  &__icon {
    margin: 0 0 $space-s 0;
  }

  &__title {
    max-width: 128px;
    color: $dark-primary;
    @include text-2;
  }
}
</style>
