<script>
import { mapGetters } from "vuex";
import defineTransferAccountType from "@/package/helpers/define-transfer-account-type.js";
import formatAccountType from "@/package/helpers/format-account-type";

export default {
  props: {
    transfer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getUserAccounts: "auth/getUserAccounts",
    }),

    formattedDate() {
      return new Date(this.transfer.created_at).toLocaleString("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },

    accountImage() {
      if (this.transferType === "Перевод между счетами") {
        return require("../../assets/icons/transfer_between_accounts.svg");
      }

      if (this.transfer.is_income) {
        return this.transfer?.from?.avatar
          ? this.transfer.from.avatar
          : require("../../assets/images/user_no_img.svg");
      } else {
        return this.transfer?.to?.avatar
          ? this.transfer.to.avatar
          : require("../../assets/images/user_no_img.svg");
      }
    },

    accountInfo() {
      if (this.transferType === "Перевод между счетами") {
        let accountFrom = defineTransferAccountType(this.transfer.from, "from");
        let accountTo = defineTransferAccountType(this.transfer.to, "to");

        return `С ${accountFrom} на ${accountTo}`;
      } else {
        let accountInfo;

        this.transfer.is_income
          ? (accountInfo = this.transfer.from)
          : (accountInfo = this.transfer.to);

        return accountInfo?.name;
      }
    },

    accountType() {
      const transferType = this.transfer.is_income ? "to" : "from";

      return formatAccountType(this.transfer[transferType]);
    },

    showAccountType() {
      return this.transferType !== "Перевод между счетами";
    },

    transferType() {
      if (
        this.getUserAccounts.find((account) => {
          return account.id === this.transfer.from?.id;
        }) &&
        this.getUserAccounts.find((account) => {
          return account.id === this.transfer.to?.id;
        })
      ) {
        return "Перевод между счетами";
      } else {
        return this.transfer.is_income
          ? "Входящий перевод"
          : "Исходящий перевод";
      }
    },

    showTransferTax() {
      return this.transfer.tax && !this.transfer.is_income;
    },

    formattedTransferAmount() {
      if (this.transfer.is_income) {
        return `+${this.transfer.amount} RC`;
      } else {
        return `-${this.transfer.amount} RC`;
      }
    },

    formattedTransferTax() {
      return `-${this.transfer.tax} RC`;
    },
  },
};
</script>

<template>
  <div class="transfer-history-item">
    <div class="transfer-history-item__date">{{ formattedDate }}</div>

    <div
      class="transfer-history-item__wrapper"
      :class="{
        'transfer-history-item__wrapper_flex':
          transferType !== 'Перевод между счетами',
      }"
    >
      <div class="transfer-history-item__info">
        <div class="info__image">
          <img :src="accountImage" alt="" />
        </div>

        <div class="info__wrapper">
          <div class="info__transfer-type">
            {{ transferType }}
          </div>

          <div class="info__account">
            {{ accountInfo }}
          </div>
        </div>
      </div>

      <div class="transfer-history-item__transfer-amount">
        <p>{{ formattedTransferAmount }}</p>
        <span v-if="showAccountType">{{ accountType }}</span>
      </div>
    </div>

    <div v-if="showTransferTax" class="transfer-history-item__tax">
      <span>Комиссия сервиса</span>
      <p>{{ formattedTransferTax }}</p>
    </div>

    <div v-if="!transfer.is_success" class="transfer-history-item__error">
      Перевод не выполнен: {{ transfer.reason }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.transfer-history-item {
  width: 100%;
  padding: $space-m $space-l;

  &:nth-child(2n + 1) {
    background-color: $background-grey;
  }

  @media (max-width: 425px) {
    padding: $space-m;
  }

  &__date {
    color: $dark-sixth;
    margin-bottom: $space-m;
    @include text-2;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_flex {
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: stretch;

        .transfer-history-item__transfer-amount {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;

          span {
            order: -1;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .info {
    &__image {
      min-width: 32px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 $space-s 0 0;

      > img {
        min-width: 100%;
        min-height: 100%;
      }
    }

    &__transfer-type {
      color: $dark-primary;
      @include body-1;
      margin-bottom: $space-xxs;
    }

    &__account {
      color: $light-sixth;
      @include text-2;
    }
  }

  &__transfer-amount {
    text-align: end;

    p {
      color: $dark-primary;
      @include body-1;
      margin-bottom: $space-xxs;
      white-space: nowrap;
    }

    span {
      @include text-2;
      color: $dark-sixth;
    }
  }

  &__tax {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $space-m;

    span {
      @include text-2;
      color: $dark-sixth;
    }

    p {
      @include text-2;
      color: $dark-primary;
    }

    @media (max-width: 576px) {
      margin-top: $space-s;
    }
  }

  &__error {
    color: $red;
    margin: $space-m 0 0;
    @include caption-1;
  }
}
</style>
